<template>
  <footer class="relative pb-20">
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="py-12 md:py-16 -mt-px">
        <!-- Footer illustration -->
        <div class="pointer-events-none -z-1" aria-hidden="true">
          <svg
            class="absolute bottom-0 left-0 transform -translate-x-1/2 ml-24 dark:opacity-40"
            width="800"
            height="264"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="400"
              cy="400"
              r="400"
              fill="url(#footerglow_paint0_radial)"
              fill-opacity=".4"
            />
            <defs>
              <radialGradient
                id="footerglow_paint0_radial"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="rotate(90 0 400) scale(315.089)"
              >
                <stop stop-color="#3ABAB4" />
                <stop offset="1" stop-color="#3ABAB4" stop-opacity=".01" />
              </radialGradient>
            </defs>
          </svg>
        </div>

        <!-- Top area: Blocks -->
        <div class="grid md:grid-cols-12 gap-8 lg:gap-20 mb-8 md:mb-12">
          <!-- 1st block -->
          <div class="md:col-span-2 lg:col-span-2">
            <!-- Logo -->
            <router-link
              class="inline-block mb-5"
              to="/"
              aria-label="Many Penny"
            >
              <img
                class="h-8"
                src="@/images/manypenny-logo.svg"
                :alt="$t('logo.alt')"
              />
            </router-link>
          </div>

          <!-- 2nd, 3rd, 4th and 5th blocks -->
          <div
            class="md:col-span-10 lg:col-span-10 grid grid-cols-2 md:grid-cols-4 gap-8"
          >
            <!-- 2nd block -->
            <div class="text-sm">
              <h6 class="font-medium uppercase mb-2">
                {{ $t("footer.contact.header") }}
              </h6>
              <ul>
                <li class="mb-1">
                  <a
                    class="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
                    :href="`mailto:${$t('footer.contact.email.address')}`"
                    >{{ $t("footer.contact.email.label") }}</a
                  >
                </li>
                <!-- <li class="mb-1">
                  <a
                    class="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
                    :href="$t('footer.contact.affiliates.address')"
                    target="_blank"
                    >{{ $t("footer.contact.affiliates.label") }}</a
                  >
                </li> -->
                <li class="mb-1">
                  <a
                    class="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
                    :href="$t('footer.contact.press.address')"
                    target="_blank"
                    >{{ $t("footer.contact.press.label") }}</a
                  >
                </li>
              </ul>
            </div>

            <!-- 3rd block -->
            <div class="text-sm">
              <h6 class="font-medium uppercase mb-2">
                {{ $t("footer.what.header") }}
              </h6>
              <ul>
                <li class="mb-1">
                  <a
                    class="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
                    href="https://insights.manypenny.ca"
                    >{{ $t("footer.what.blog") }}</a
                  >
                </li>
                <li class="mb-1">
                  <a
                    class="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
                    :href="$t('footer.what.chrome.href')"
                    target="_blank"
                    >{{ $t("footer.what.chrome.display") }}</a
                  >
                </li>
              </ul>
            </div>

            <!-- 4th block -->
            <div class="text-sm">
              <h6 class="font-medium uppercase mb-2">
                {{ $t("footer.support.header") }}
              </h6>
              <ul>
                <li class="mb-1">
                  <a
                    class="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
                    href="https://support.manypenny.ca/hc"
                    target="_blank"
                    >{{ $t("footer.support.hc") }}</a
                  >
                </li>
                <!-- <li class="mb-1">
                  <a
                    class="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
                    :href="$t('footer.contact.phone.uri')"
                    >{{ $t("footer.contact.phone.display") }}</a
                  >
                </li> -->
              </ul>
            </div>

            <!-- 5th block -->
            <div class="text-sm">
              <iframe
                src="https://status.manypenny.ca/badge?theme=dark"
                width="100%"
                height="30"
                frameborder="0"
                scrolling="no"
              ></iframe>
            </div>
          </div>
        </div>

        <!-- Bottom area -->
        <div class="md:flex md:items-center md:justify-between">
          <!-- Middle links -->
          <div
            class="text-sm md:order-1 text-gray-700 mb-2 md:mb-0 md:text-right"
          >
            <a
              class="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
              href="https://www.manypenny.ca/privacy"
              target="_blank"
              >{{ $t("footer.terms.privacy") }}</a
            >
            ·
            <a
              class="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
              href="https://www.manypenny.ca/cookie"
              target="_blank"
              >{{ $t("footer.terms.cookie") }}</a
            >
            ·
            <a
              class="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
              href="https://www.manypenny.ca/terms"
              target="_blank"
              >{{ $t("footer.terms.terms") }}</a
            >
            ·
            <a
              class="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
              href="https://www.manypenny.ca/eula"
              target="_blank"
              >{{ $t("footer.terms.eula") }}</a
            >
            ·
            <a
              class="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
              href="https://www.manypenny.ca/disclaimer"
              target="_blank"
              >{{ $t("footer.terms.disclaimer") }}</a
            >
            ·
            <a
              class="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
              href="https://www.manypenny.ca/acceptable-use"
              target="_blank"
              >{{ $t("footer.terms.acceptable") }}</a
            >
            <br />
            <button
              class="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
              v-on:click="consentModal"
              id="termly-consent-preferences"
            >
              {{ $t("footer.terms.consent") }}
            </button>
            ·
            <a
              class="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
              href="https://app.termly.io/notify/79481694-a4be-4987-a49c-595223d87172"
              target="_blank"
              >{{ $t("footer.terms.donotsell") }}</a
            >
          </div>

          <!-- Copyrights note -->
          <div class="text-gray-600 dark:text-gray-400 text-sm mr-4">
            {{ $t("footer.built.pre") }}
            <font-awesome-icon
              icon="fa-solid fa-heart"
              class="mx-0.5"
              :style="{
                color: 'red',
              }"
            />
            {{ $t("footer.built.post") }}
            <br />
            &copy; {{ new Date().getFullYear() }}
            <a href="https://www.foundability.ca" target="_blank"
              >Foundability Technologies Inc.</a
            >
            {{ $t("footer.copyrights.note") }}
          </div>
        </div>

        <div class="flex">
          <!-- Social links -->
          <ul class="flex mb-4 md:order-2 md:mb-0 mt-4">
            <li>
              <a
                class="flex justify-center items-center text-white bg-teal-500 dark:text-manypenny-primary-light dark:bg-gray-800 hover:underline hover:bg-teal-600 rounded-full transition duration-150 ease-in-out"
                href="https://www.facebook.com/app.manypenny"
                aria-label="Facebook"
                target="_blank"
              >
                <svg
                  class="w-8 h-8 fill-current"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z"
                  />
                </svg>
              </a>
            </li>
            <li class="ml-4">
              <a
                class="flex justify-center items-center text-white bg-teal-500 dark:text-manypenny-primary-light dark:bg-gray-800 hover:underline hover:bg-teal-600 rounded-full transition duration-150 ease-in-out"
                href="https://www.instagram.com/app.manypenny"
                aria-label="Instagram"
                target="_blank"
              >
                <svg
                  class="w-8 h-8 fill-current"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="20.145" cy="11.892" r="1" />
                  <path
                    d="M16 20c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z"
                  />
                  <path
                    d="M20 24h-8c-2.056 0-4-1.944-4-4v-8c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zm-8-14c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2v-8c0-.935-1.065-2-2-2h-8z"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </div>

        <div class="md:flex mt-10">
          <!-- Attributions note -->
          <div class="text-gray-800 dark:text-gray-600 text-xs">
            {{ $t("footer.attributions.apple") }}<br />
            {{ $t("footer.attributions.google") }}<br />
            {{ $t("footer.attributions.others") }}
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "PageFooter",
  methods: {
    consentModal(e) {
      e.preventDefault();
      if (window && window.displayPreferenceModal) {
        window.displayPreferenceModal();
      }
    },
  },
};
</script>
